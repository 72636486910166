body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 50px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

input, textarea {
  width: calc(100% - 20px);
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ddd;
}

textarea {
  height: 100px;
}

button {
  padding: 10px 15px;
  font-size: 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

h2 {
  font-size: 1.8em;
  margin-top: 30px;
  color: #333;
}

input[type="text"] {
  width: calc(100% - 20px);
  margin-bottom: 10px;
}

.ervaring {
  padding: 10px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.ervaring h3 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
}

.ervaring p {
  margin: 5px 0;
  font-size: 1em;
  line-height: 1.5em;
}
