/* Basis styling */
body {
  font-family: Arial, sans-serif;
  background-image: url('../../images/background.png'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
}

/* Header styling */
.App-header {
  background-color: #333;
  color: white;
  padding: 1rem;
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Login en Registreer knoppen in de header */
.auth-buttons {
  display: flex;
  gap: 1rem;
}

.auth-buttons button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.auth-buttons button:hover {
  background-color: #0056b3;
}

/* Main container */
.App {
  text-align: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

/* Zoekbalk styling */
.search-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.search-bar {
  width: 100%;
  padding: 15px;
  font-size: 1.2em;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

/* Suggesties styling */
.suggestions-list {
  position: absolute;
  top: calc(100% + 5px); /* Direct onder de zoekbalk */
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

/* Results container */
.results-container {
  max-height: 50vh;
  overflow-y: auto;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Summary styling */
.summary-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.worked-summary,
.didnt-work-summary {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.worked-summary h2,
.didnt-work-summary h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.worked-summary p,
.didnt-work-summary p {
  margin: 0;
  padding: 5px 0;
}

/* Media Query voor mobiele responsiviteit */
@media (max-width: 768px) {
  .summary-container {
    grid-template-columns: 1fr;
  }

  .App {
    width: 90%;
    margin: 20px auto;
  }

  .search-container {
    width: 90%;
  }

  .suggestions-list {
    max-width: 90%;
  }
}
